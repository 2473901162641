<template>
  <div class="widgetContainer bg-white cardType" data-testid="card-type-selection">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="el-icon-close cancel" @click="drawerClose" />
        <p class="title">
          {{ $t('physicalCard_Create_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <div class="listWithActionsv2">
        <ul>
          <li
            @click="onSelect('physical')"
            data-testid="card-type-selection-physical"
            v-if="isPhysicalCardEnabled">
            <div class="details">
              <p class="label">
                {{ $t('cardType_physical_title') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
          <li
            @click="onSelect('virtual')"
            data-testid="card-type-selection-virtual"
            v-if="isVirtualCardEnabled">
            <div class="details">
              <p class="label">
                {{ $t('cardType_virtual_title') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import CardMixin from '../mixins/card';
import { getUIConfig } from '@/utils/env';
export default {
  name: 'CardTypeForm',
  mixins: [CardMixin],
  data() {
    return {
      isPhysicalCardEnabled: getUIConfig('isPhysicalCardEnabled'),
      isVirtualCardEnabled: getUIConfig('isVirtualCardEnabled'),
      formData: {
        cardType: ''
      }
    };
  },
  methods: {
    ...mapMutations('card', ['updateCreateCardData']),
    cancel() {
      this.drawerBack();
    },
    onSelect(type) {
      this.formData.cardType = type;
      this.next();
    },
    next() {
      this.updateCreateCardData({
        accountId: this.getSelectedAccount.id,
        cardType: this.formData.cardType
      });

      setTimeout(() => {
        this.drawerPush('CardSpendingForm');
      }, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.cardType {
  .widgetContainer__body {
    padding-top: 32px;
  }
}
</style>
